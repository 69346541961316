import { request } from 'shared/lib/request';
import { supportTypes } from 'shared/constants';
import { v4 as uuidv4 } from 'uuid';

const bigFileSize = 20 * 1024 * 1024; //20 Mb
const veryBigFileSize = 10 * bigFileSize; //200 Mb
const defaultChunkSize = 1024 * 1024; //1Mb

export const saveDataTransferItems = async (dataTransferItems, rootDirId, allowPartialUpload) => {
  const traverseFileTreePromise = (item, dirId) => {
    return new Promise((resolve, reject) => {
      if (item instanceof File) {
        (async () => {
          try {
            await saveEntity(
              dirId,
              item.type ? item : await fixFileType(item),
              true
            );
            resolve(item);
          } catch (error) {
            reject(error);
          }
        })();
      } else if (item.isFile) {
        //can don't sync files upload if don't care about folder update after finish
        item.file(async (file) => {
          try {
            await saveEntity(
              dirId,
              file.type ? file : await fixFileType(file),
              true,
              allowPartialUpload
            );
            resolve(file);
          } catch (error) {
            reject(error);
          }
        });
      } else if (item.isDirectory) {
        let dirReader = item.createReader();
        dirReader.readEntries(async (entries) => {
          let entriesPromises = [];
          const folderId = await saveEntity(dirId, item, false);
          if (!folderId) resolve();
          for (let entr of entries)
            entriesPromises.push(traverseFileTreePromise(entr, folderId));
          resolve(Promise.all(entriesPromises));
        });
      }
    });
  };

  return new Promise((resolve, reject) => {
    let entriesPromises = [];
    if (dataTransferItems instanceof FileList) {
      [...dataTransferItems].forEach((file) => {
        entriesPromises.push(traverseFileTreePromise(file, rootDirId));
      });
    } else {
      for (let it of dataTransferItems)
        entriesPromises.push(
          traverseFileTreePromise(
            it.getAsEntry ? it.getAsEntry() : it.webkitGetAsEntry(),
            rootDirId
          )
        );
    }
    Promise.all(entriesPromises).then(resolve).catch(reject);
  });
};

const fixFileType = async (fileObj) => {
  const fileType = fileObj.name.slice(fileObj.name.lastIndexOf('.') + 1);

  const supportType = Object.values(supportTypes).find(
    (item) => item.fileType === fileType
  );

  //change empty file type to supported type
  if (supportType) {
    fileObj = await new Promise((resolve) => {
      const reader = new FileReader();

      reader.readAsArrayBuffer(fileObj);

      reader.onload = () =>
        resolve(
          new File([new Uint8Array(reader.result)], fileObj.name, {
            type: supportType.mimeType
          })
        );
    });
  }
  return fileObj;
};


//for future use
// const getHash = async (file) => {
//   //const filename = file.name;
//   //const filesize = file.size;
//   const reader = new FileReader();
//   reader.onload = function (ev) {
//     crypto.subtle
//       .digest('SHA-256', ev.target.result)
//       .then((hashBuffer) => {
//         const hashArray = Array.from(new Uint8Array(hashBuffer));
//         const hashHex = hashArray
//           .map((b) => b.toString(16).padStart(2, '0'))
//           .join('');
//         return { hash: hashHex, reader };
//       })
//       .catch((ex) => console.error(ex));
//   };
//   reader.onerror = function (err) {
//     console.error('Failed to read file', err);
//   };
//   reader.readAsArrayBuffer(file);
// };

const shortFileName = (fileName) => fileName.length > 40 ? fileName.substr(0, 37) + "..." : fileName;

const saveBigFile = async (dirId, dirUid, file) => {
  const session = uuidv4();
  const chunkSize =
    file.size < veryBigFileSize
      ? defaultChunkSize
      : Math.floor(file.size / 100);

  //let counter = 0;

  for (let start = 0; start < file.size; ) {
    //counter++;
    const chunk = file.slice(start, start + chunkSize);
    const data = new FormData();
    data.set('data', chunk);
    await fetch(window.location.origin + '/api/v1/Documents/UploadPartial', {
      method: 'POST',
      headers: {
        Name: encodeURI(file.name),
        MimeType: file.type,
        ...(dirId && { DirectoryId: dirId }),
        ...(dirUid && { DirectoryUid: dirUid }),
        //...(counter !== 3 && { FileSize: file.size }),
        FileSize: file.size,
        FileSession: session
      },
      body: data
    })
      .then((res) => {
        if (res.ok) {
          const progress = (((start + chunkSize) / file.size) * 100).toFixed(2);
          var evt = new CustomEvent('uploadProgressChanged', {
            detail: {name: shortFileName(file.name), progress, size: file.size}
          });
          window.dispatchEvent(evt);
          start += chunkSize;
          return progress;
        } else {
          if (res.status === 416){
            alert('Превышена квота на хранилище, загрузка файла отменена');
            throw new Error();
          }
          return null;
        }
      })
      .then(async (data) => {
        if (!data) {
          if (
            window.confirm(
              `Возникла ошибка при загрузке большого файла. Попробовать докачать?`
            )
          ) {
            const res = await request(
              `v1/documents/GetPartialSize?fileSession=${session}`
            );
            start = res?.UploadedSize;
          } else throw new Error();
        }
      });
  }

  var evt = new CustomEvent('uploadProgressChanged', {
    detail: {name: shortFileName(file.name), progress: "100", size: file.size, finished: true}
  });
  window.dispatchEvent(evt);
};

const saveEntity = async (dirId, item, isFile = true, allowPartialUpload = false) => {
  const formData = new FormData();
  let uid = null;
  if (isFile && dirId) {
    formData.append('DirectoryId', dirId);
  } else {
    //directory.AccessType === accessType.OnlyUpload
    const regex = /^\/link\/(.*)$/i;
    const match = window.location.pathname.match(regex);
    if (match && match.length === 2) {
      uid = match[1];
      formData.append('DirectoryUid', uid);
    }
  }

  if (isFile) {
    debugger;
    if (item.size > bigFileSize && allowPartialUpload) return await saveBigFile(dirId, uid, item);

    const evtStart = new CustomEvent('uploadProgressChanged', {
      detail: {name: shortFileName(item.name), progress: "", size: item.size}
    });
    window.dispatchEvent(evtStart);

    formData.append('file', item);

    try {
      await request('v1/Documents/Upload', {
        method: 'POST',
        body: formData
      });
      const evtEnd = new CustomEvent('uploadProgressChanged', {
        detail: {name: shortFileName(item.name), progress: "100", size: item.size, finished: true}
      });
      window.dispatchEvent(evtEnd);

    } catch (error) {
      throw error;
    }
  } else {
    //if require to create
    const folderParams = {
      Name: item.name,
      Description: `${item.name} folder`,
      Order: 100,
      Type: 'Default',
      IconId: null,
      RoleIds: [],
      UserIds: [],
      ParentId: dirId
    };
    try {
      const res = await request('v1/DocumentDirectory/AddSubDirectory', {
        method: 'POST',
        body: folderParams
      });
      return res.Id;
    } catch (error) {
      if (error.ErrorCode === 409) {
        const folders = await request(`v1/DocumentDirectory/Get?id=${dirId}`);
        const existingFolder = folders[0]?.Children.find(
          (x) => x.Name === item.name
        );
        if (existingFolder) return existingFolder.Id;
      } else {
        console.log(`ошибка при создания папки ${item.name}`);
      }
      return 0;
    }
  }
};
